<template>
    <div id="recorder" v-loading="loading" :element-loading-text="$t('alarm.a0')">
           <!-- 页面标题 -->
          <div class="header-title">
            <!-- <span>{{ $t('video.viddata024') }}</span> -->
            <span>录像机管理</span>
          </div>
          <div class="header">
              <div class="header-left">
                <div class="header-condition">
                  <!-- <span>{{$t('energy.enedata254') }}</span> -->
                  <span>录像机类型</span>
                  <a-select v-model="cameraType" style="width: 300px">
                    <a-select-option
                        v-for="(itme, index) in this.cameraTypes"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                      </a-select-option>
                  </a-select>
                </div>
                <div class="header-condition">
                  <span>录像机名称</span>
                  <a-input v-model="recorderName" placeholder="请输入录像机名称" />
                </div>
              </div>
              <div>
                <a-button type="primary" style=""  @click="search()"> {{ $t('energy.enedata006') }} </a-button>
              </div>
          </div>
          <div class="main" ref="main">
                <a-table
                    :columns="columns" 
                    :data-source="recorders"
                    :row-key="record => record.recorderId"  
                    :pagination="pagination"  
                    :scroll="tscroll"
                    size="middle"
                >
                 <!-- <template slot="name" slot-scope="text, record">
                    <div class="editable-row-operations">
                        <a-input v-model="record.name"  @change="e => handleValChange(text,e.target.value, record.cameraId,'name')"></a-input>
                    </div>
                  </template> -->

                  <template slot="opea" slot-scope="text, record ">
                        <div class="editable-row-operations">
                            <a-button type="primary" @click="() => editRecord(record)"  size="small"  style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                            <a-button type="danger" @click="() => deleteRecord(record)" size="small"  style="margin-right:5px;">删除</a-button>
                          
                        </div>
                    </template>
                </a-table>
          </div>
           <div>
                <a-button type="primary" style=""  @click="add()"> 添加 </a-button>
              </div>
          <recorder-dialog :id="recorderId" :cameraTypes="cameraTypes" :recorderCollections="recorderCollections"></recorder-dialog>
    </div>
    
  </template>
  <script>
  import { getVideoRecorerList,deleteVideoRecorerInfo,saveVideoRecorerInfo,getVideoRecorerInfo,getVideoParamInfo } from "../../api/video";
  import moment from "moment";
  import recorderEditDialog from "./recorderEditDialog.vue";
  export default {
    data(){
      return {
        
          loading:false,
          tscroll:{x:1000,y:280},
          columns:[
                {
                   
                    title: "NO",
                    dataIndex: "NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                        index +
                        1
                        }`,
                    width: 60,
                    align: "center",
                },
              {title: this.$t('video.viddata009')		 , dataIndex: 'recorderName'                      ,width:160        }, //,scopedSlots: { customRender: 'name' }                   
              {title: '录像机编号'		                  , dataIndex: 'recorderCh'                        ,width:120        },  
              {title: this.$t('video.viddata012')		 , dataIndex: 'recorderType'                      ,width:150   
              ,customRender: (text, record, index) => `${ this.getRecorderType(record) }`,     },                    
              {title: this.$t('video.viddata010')		 , dataIndex: 'recorderIpAddress'                 ,width:160        },                    
              {title: this.$t('video.viddata011')		 , dataIndex: 'recorderVideoPort'                 ,width:100        },                   
              {title: '操作'		 , dataIndex: 'opea'                  ,width:150 ,scopedSlots: { customRender: 'opea' }       },                    
            //   {title: this.$t('video.viddata010')		 , dataIndex: 'recorderUserPassword'               ,width:100        },                    
              // {title: this.$t('video.viddata014')		 , dataIndex: 'bandLimitingFlag' 				        ,width:150		    },	                 
              // {title: this.$t('video.viddata016')		 , dataIndex: 'omnidirectionalCorrection'       ,width:150        },   
          ],
          pagination: {
                total: 0, //数据总数
                pageSize: 30, //每页中显示10条数据
                showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                showQuickJumper: true, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                onShowSizeChange: (current, pageSize) => {
                    this.pagination.defaultCurrent = 1;
                    this.pagination.pageSize = pageSize;
                },
                // 改变每页数量时更新显示
                onChange: (current, size) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = size;
                },
          },
          siteId:'',
          recorderId:0,
          recorders:[],
          recorder:{},
          recorderName:'',
          cameras:[],
          camera:{},
          cameraType:'',
          cameraTypes:[],
          recorderCollections:[],
          cameraCollections:[],
          recorderVisible:false,
  
      }
    },
    activated() {
      this.loadInitData();  
    },
    mounted() { 
      // 调用表格高度计算方法
      this.tableSize();
      // 根据窗口变化计算表格高度
      window.addEventListener("resize", this.tableSize);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.tableSize);
    },
    methods:{
        loadInitData(){
            this.loading = true;
            let param = {
                action:     1,
                clientId:   this.$store.getters.clientId,
                sitegrId:   this.$store.getters.sitegrId,
                siteId:     this.$route.query.id,
                tenantId:   0,
            }
            getVideoParamInfo(param)
            .then((res) => {
                    console.log("res",res);
                    
                    // this.recorders = recorders;
                    // this.recorder = recorder;
                    // this.cameras = cameras;
                    // this.camera = camera;
                    this.cameraTypes = res.data.cameraTypes;
                    this.recorderCollections = res.data.recorderCollections;
                    // this.cameraCollections = cameraCollections;
                    this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        
        search(){
            this.loading = true;
            let param = {
                action:     1,
                clientId:   this.$store.getters.clientId,
                sitegrId:   this.$store.getters.sitegrId,
                siteId:     this.$route.query.id,
                tenantId:   0,
                type: this.cameraType,
                name:this.recorderName,
            }
            getVideoRecorerList(param)
            .then((res) => {
                    console.log("res",res);
                    
                    this.recorders = res.data.recorders;
                    // this.recorder = recorder;
                    // this.cameras = cameras;
                    // this.camera = camera;
                    // this.cameraTypes = cameraTypes;
                    // this.recorderCollections = recorderCollections;
                    // this.cameraCollections = cameraCollections;
                    this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        getRecorderType(record){
            let name = '-';
            this.cameraTypes.forEach(element => {
                if(element.no===record.recorderType+''){
                    name = element.text;
                }
            });
            return name;
        },
        add(){
            this.recorderId=0;
            this.recorderVisible=true;
        },
        editRecord(record){
            this.recorderId=record.recorderId;
            this.recorderVisible=true;
        },
        deleteRecorder(ids){
            let param = {
                action:     1,
                clientId:   this.$store.getters.clientId,
                sitegrId:   this.$store.getters.sitegrId,
                siteId:     this.$route.query.id,
                tenantId:   0,
                ids: ids,
            }
            this.loading = true;
            deleteVideoRecorerInfo(param)
                .then((res) => {
                    console.log("deleteVideoRecorerInfo res",res);
                    if(res.errorCode==='00' || res.errorCode==='05'){
                        this.$message.info(res.msg);
                        this.search();
                    }else{
                        this.$message.error(res.msg);
                    }
                    this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        deleteRecord(record){
            let ids = [];
            ids.push(record.recorderId);
            this.$confirm({
                title: "确定要删除录像机信息吗?",
                // title:this.$t("energy.enedata184"),
                centered: true,
                onOk: () => {
                    this.deleteRecorder(ids)
                }
            });
        },
        batchDeleteRecord(record){
            let ids = [];
            ids.push(record.recorderId);
            this.$confirm({
                title: "确定要所选择的删除录像机信息吗?",
                // title:this.$t("energy.enedata184"),
                centered: true,
                onOk: () => {
                    this.deleteRecorder(ids)
                }
            });
        },
        
        // 表格高度计算
        tableSize() {
            setTimeout(() => {
                let height = this.$refs.main.clientHeight;
                let width = this.$refs.main.clientWidth;
                let tableHeight = height - 100;
                this.tscroll.y = tableHeight;
                // this.tscroll.x = width;
                console.log(this.tscroll);
            }, 0);
        },
    },
    components:{
        'recorder-dialog':recorderEditDialog,
    }
  }
  </script>
  <style scoped>
  
  #recorder {
      width: 100%;
      height: 100%;
      padding: 20px;
      font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
  }
  .header-title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 18px;
  }
  .header-title::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 22px;
      margin-right: 20px;
      background-color: #7682ce;
  }
  .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
  }
  .header-left {
      display: flex;
      justify-content: flex-start;
  }
  .header-condition {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
  }
  .main{
      width: 100%;
      height: calc(100% - 120px);
  }
  </style>