<template>
  <div id="recorderEditDialog" ref="recorderEditDialog">
    <a-modal v-model="visible" :title="title" :width="720" :getContainer="() => this.$refs.recorderEditDialog" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
            <a-form-model ref="ruleForm" 
            :model="form" 
            :rules="rules"
            :label-col="labelCol" 
            :wrapper-col="wrapperCol"  >
            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 1.录像机所属 -->
                    <a-form-model-item label="录像机所属" ref="parentId" prop="parentId">
                        <a-select v-model="form.parentId" placeholder="请选择录像机所属">
                            <a-select-option
                                v-for="(itme, index) in recorderCollections"
                                :key="index"
                                :value="itme.no"
                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                     <!-- 2.录像机类型 -->
                     <a-form-model-item label="录像机类型" ref="recorderType" prop="recorderType">
                        <a-select v-model="form.recorderType" placeholder="请选择录像机类型" >
                            <a-select-option
                                v-for="(itme, index) in cameraTypes"
                                :key="index"
                                :value="itme.no"
                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 3.录像机名称 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="recorderName" prop="recorderName">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        录像机名称&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.recorderName"
                            @blur="
                            () => {
                                $refs.recorderName.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机名称"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <!-- 4.录像机编号 :label="$t('energy.enedata490')"-->
                    <a-form-model-item  ref="recorderCh" prop="recorderCh">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        录像机编号&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input-number style="width:100%;"
                            v-model="form.recorderCh"
                            @blur="
                            () => {
                                $refs.recorderCh.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机编号"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 3.录像机IP :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="recorderIpAddress" prop="recorderIpAddress">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        录像机IP&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <!-- <a-input 
                            v-model="form.recorderIpAddress"
                            @blur="
                            () => {
                                $refs.recorderIpAddress.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机IP"
                        /> -->

                        <a-input v-model="form.recorderIpAddress"
                            @blur="
                                () => {
                                    $refs.recorderIpAddress.onFieldBlur();
                                }
                            "
                            placeholder="请输入录像机IP"
                        >
                            <a-select slot="addonBefore" v-model="form.protocol" style="width: 90px">
                                <a-select-option value="http">
                                    http://
                                </a-select-option>
                                <a-select-option value="https">
                                    https://
                                </a-select-option>
                            </a-select>
                        </a-input>
                    </a-form-model-item>
                

                </a-col>
                <a-col :span="12">
                    <!-- 3.录像机端口 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="recorderVideoPort" prop="recorderVideoPort">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        录像机端口&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.recorderVideoPort"
                            @blur="
                            () => {
                                $refs.recorderVideoPort.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机端口"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 3.录像机账户 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="recorderUserId" prop="recorderUserId">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        录像机账户&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.recorderUserId"
                            @blur="
                            () => {
                                $refs.recorderUserId.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机账户"
                        />
                    </a-form-model-item>
                

                </a-col>
                <a-col :span="12">
                    <!-- 3.录像机密码 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="recorderUserPassword" prop="recorderUserPassword">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        录像机密码&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <!-- <a-input 
                            v-model="form.recorderUserPassword"
                            @blur="
                            () => {
                                $refs.recorderUserPassword.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机密码"
                        /> -->

                        <a-input-password  v-model="form.recorderUserPassword"
                            @blur="
                            () => {
                                $refs.recorderUserPassword.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机密码"/>
                    </a-form-model-item>
                </a-col>
            </a-row>
                
                
            <a-form-model-item v-bind="tailFormItemLayout">
                <a-button type="primary" @click="onSubmit">
                    {{$t('energy.enedata173')}}
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm" v-if="id==0">
                    {{$t('energy.enedata333')}}
                </a-button>
            </a-form-model-item>
                
            </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
  import { saveVideoRecorerInfo,getVideoRecorerInfo } from "../../api/video";
import moment from 'moment';
export default {
  name: 'recorderEditDialog',
  props: {
    
    id: {
      type: Number,
      default:0,
    },
    cameraTypes: {
      type: Array,
      default:[{no:'',text:''}],
    },
    recorderCollections:{
      type: Array,
      default:[{no:'',text:''}],
    }
  },
  data() {
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",;']/im;

        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
            callback();
        }
    };

    let numberVail = (rule, value, callback) => {
        if(value==0) {
            callback(new Error(this.$t('energy.enedata524')));
        } else {
            callback();
        }
    };
    return {
        cloading:false,        
        visible:false,
        title: '录像机设置',
        form:{
            recorderId: 0,
            recorderName: "",
            recorderType: '',
            recorderIpAddress: "",
            recorderCh: '',
            recorderUserId: "",
            recorderUserPassword: "",
            siteId: 0,
            parentId: '',
            recorderVideoPort:'',
            recorderConnectPort:'',
            protocol:'http',

        },
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },

        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 12,
                    offset: 12,
                },
            },
        },
        
        rules: {
            recorderCh: [
                { required: true, message: '请输入录像机编号' , trigger: 'blur' }, //this.$t('energy.enedata505')
                { validator:numberVail, trigger: 'blur' },
            ],
            recorderName: [
                { required: true, message: '请输入录像机名称', trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            recorderIpAddress: [
                { min: 1, max: 50, message: '录像机IP/URL地址长度在1~50个字符内', trigger: 'blur' },
                // { validator:normalName, trigger: 'blur' },
            ],
            recorderUserId: [
                { min: 1, max: 50, message: '录像机管理账号/KEY长度在1~50个字符内', trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            recorderUserPassword: [
                { min: 1, max: 50, message: '录像机管理密码/token长度在1~50个字符内', trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            recorderType: [{ required: true, message: '请选择录像机类型', trigger: 'change' }],
            parentId: [{ required: true, message: '请选择录像机所属', trigger: 'change' }],
            
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
        
        
    };
  },
  mounted(){
   
  },
  watch:{
    "$parent.recorderVisible":{
      handler(val){
          console.log('watch recorderVisible>>>',val)
          if(val){              
              this.visible = val;
              this.resetForm();
              if(this.id>0){
                  this.loadInfo();
              }else{
                //   this.form = {
                //         recorderId: 0,
                //         recorderName: "萤石云",
                //         recorderType: '',
                //         recorderIpAddress: "https://open.ys7.com/",
                //         recorderCh: '',
                //         recorderUserId: "5d8cc5f4d6174e2cb7cbe79d05879397",
                //         recorderUserPassword: "52940d0d154f07771ceda83710719379",
                //         siteId: 0,
                //         parentId: '',
                //         recorderVideoPort:'',
                //         recorderConnectPort:''
                //     };
                    this.form = {
                        recorderId: 0,
                        recorderName: "",
                        recorderType: '',
                        recorderIpAddress: "",
                        recorderCh: '',
                        recorderUserId: "",
                        recorderUserPassword: "",
                        siteId: 0,
                        parentId: '',
                        recorderVideoPort:'',
                        recorderConnectPort:'',
                        protocol:'http',
                    };
                    this.cloading = false;
              }
              console.log('watch recorderVisible form>>>',this.form)
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          console.log('watch visible>>>',val)
          if(!val){
              this.$parent.recorderVisible =  val;
              this.resetForm();
              console.log('watch visible  this.$parent.recorderVisible>>>', this.$parent.recorderVisible);
              this.cloading = false;
          }
      },
      deep:true,
      immediate:true,
    },
    
  },
  methods: {
        handleChange(e){
            
        },
        loadInfo(){
            this.cloading = true;
            getVideoRecorerInfo(this.id)
            .then((res) => {
                console.log("getVideoRecorerInfo Result",res.data);
                if(res.errorCode === '00'){
                    if(res.data){
                        this.form = {
                            recorderId: res.data.recorder.recorderId,
                            recorderName: res.data.recorder.recorderName,
                            recorderType: res.data.recorder.recorderType+"",
                            recorderIpAddress: res.data.recorder.recorderIpAddress,
                            recorderCh: res.data.recorder.recorderCh,
                            recorderUserId: res.data.recorder.recorderUserId,
                            recorderUserPassword: res.data.recorder.recorderUserPassword,
                            siteId: res.data.recorder.siteId,
                            parentId: res.data.recorder.parentId+"",
                            recorderVideoPort:res.data.recorder.recorderVideoPort,
                            recorderConnectPort:res.data.recorder.recorderConnectPort,
                            protocol:res.data.recorder.protocol,
                        };

                        console.log("getVideoRecorerInfo Result",this.form);
                    }
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('getVideoRecorerInfo',err);
            });
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: '确认要保存所设置的录像机信息吗？',//
                        centered: true,
                        onOk: () => {
                            this.savaRecorderInfo();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            if(this.$refs.ruleForm){
                this.$refs.ruleForm.resetFields();
            }
        },
        savaRecorderInfo(){
            
            let param =  {
                    action:1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    tenantId: 0,
                    recorder:this.form,
                };
            this.cloading = true;
            console.log("savaRecorderInfo param",param);
            saveVideoRecorerInfo(param)
            .then((res) => {
                console.log("savaRecorderInfo Result",res);
                console.log("savaRecorderInfo ErrorCode",res.errorCode);
                if(res.errorCode == '00' || res.errorCode == '03' ){
                   this.$message.success(res.msg);
                   this.visible = false;
                   this.$parent.search();
                } else {
                    this.$message.error(res.msg);
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('savaRecorderInfo',err);
            });
        },
  },
};
</script>
<style scoped>
    #recorderEditDialog{
        height: 100%;
        width:100%;
        position: relative;
    }
    div /deep/.ant-modal-body{padding:10px;}
</style>
